import { css } from '@emotion/react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { graphql, PageProps } from 'gatsby'
import { Disqus } from 'gatsby-plugin-disqus'
import { VFC } from 'react'

import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { MarkDown } from '../components/MarkDown'
import { Spacer } from '../components/Spacer'
import { Text } from '../components/Text'
import { PostPageProps } from '../type'

const PostPage: VFC<PageProps<PostPageProps>> = (props) => {
  const {
    data: {
      markdownRemark: {
        id,
        html,
        timeToRead,
        excerpt,
        fields: { slug },
        frontmatter: { title, tags, date, cover }
      }
    }
  } = props

  const disqusConfig = {
    identifier: id,
    title,
    url: `https://kimchunsick.me/${slug}`
  }

  return (
    <Layout title={title} description={excerpt === '…' ? undefined : excerpt} image={cover?.publicURL} type='article'>
      <PostInfo>
        {cover?.publicURL && <CoverImage src={cover.publicURL} />}
        <Text as='h1' size={40} weight={500} isWrap>
          {title}
        </Text>
        <Spacer y={0.2} />
        <PostSubInfo>
          <SubInfo>
            <Icon.Calendar line='gray2' />
            <Text as='span' size={20} weight={200} color='gray1' isWrap>
              {dayjs(date).format('YYYY년 MM월 DD일')}
            </Text>
          </SubInfo>
          {/* <SubInfo>
            <Icon.Clock line='gray2' />
            <Text as='span' size={20} weight={200} color='gray1' isWrap>
              {timeToRead}분
            </Text>
          </SubInfo> */}
        </PostSubInfo>
        <Spacer y={0.5} />
        <TagBox>
          {tags.map((tag) => {
            return (
              <Tag key={`${title}-${date}-${tag}`}>
                <Text as='span' size={20} weight={200} color='primary2' isWrap>
                  #{tag}
                </Text>
              </Tag>
            )
          })}
        </TagBox>
      </PostInfo>
      <MarkDown html={html} />
      <Disqus config={disqusConfig} />
    </Layout>
  )
}

const CoverImage = styled.img`
  width: 100%;
  height: 17rem;
  margin-bottom: 8px;
  border-radius: 16px;
  object-fit: cover;
`

const PostInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4rem;
`

const TagBox = styled.div`
  display: flex;
  & > span:not(:last-child) {
    margin-right: 0.5rem;
  }
`

const Tag = styled.span`
  padding: 0.2rem 0.4rem;
  ${({ theme }) => css`
    background-color: ${theme.color.gray3};
    border-radius: 0.25rem;
  `}
`

const PostSubInfo = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`

const SubInfo = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
`

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      excerpt(truncate: true)
      fields {
        slug
      }
      frontmatter {
        title
        tags
        date
        cover {
          publicURL
        }
      }
    }
  }
`

export default PostPage
